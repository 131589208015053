<template>
  <b-modal
    :id="`mark-order-claim-paid-modal-${order_claim.id}`"
    :modal-class="'default-modal'"
    header-class="justify-content-between"
    :hide-footer="true"
    @hidden="hidden"
  >

    <template #modal-header="{ close }">
      <div class="text-center">
        <h2 class="highlighted-color1">
          <span>Marcar comissão como paga</span>
        </h2>
      </div>
      <!--begin::Close-->
      <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close" @click="close()">
          <span class="svg-icon svg-icon-1">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
            </svg>
          </span>
      </div>
      <!--end::Close-->
    </template>
    <AlertWraper
      :content="content"
      :type="content_type"
    />
    <div class="mt-2">
        <form class="default-form" @submit.prevent="">
          <div class="text-center">
            Você tem certeza que deseja marcar a comissão de <strong>{{ order_claim.commission_amount / 100 | formatMoney }}</strong> para <strong>{{ order_claim.claimed_user.name }}</strong> como paga?
          </div>
          <div class="d-flex justify-content-around mt-4">
            <Button
              class="btn btn-success"
              @click="submit"
              :loading="loader"
            >
              Marcar como pago
            </Button>
          </div>
        </form>
    </div>
  </b-modal>
</template>

<script>

import OrderClaimsService from '@/modules/order-claims/services/order-claims-service'
import NotificationService from '@/modules/notifications/services/notifications-service'
import { parseErrorResponseToArray } from '@/utils'

import AlertWraper from '@/components/common/Alert/AlertWraper'
import Button from '@/components/common/Button/Button'

export default {
  name: 'MarkCommissionAsPaidModal',
  props: ['order_claim'],
  components: {
    AlertWraper,
    Button
  },
  data () {
    return {
      content: null,
      content_type: 'error',
      loader: false
    }
  },
  methods: {
    /**
     * On modal hidden
     */
    hidden () {
      this.content = null
    },
    /**
     * Submit form
     */
    async submit () {
      this.loader = true

      try {
        await OrderClaimsService.markCommissionAsPaid(this.order_claim.id)
        const notification = {
          notification: {
            type: 'success',
            content: 'Comissão marcada como paga'
          }
        }
        NotificationService.createGlobalSuccessNotification(notification)
        this.$emit('paid', this.order_claim)
        this.$bvModal.hide(`mark-order-claim-paid-modal-${this.order_claim.id}`)
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }

      this.loader = false
    }
  }
}
</script>
