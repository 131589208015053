<template>
  <MainLayout>
    <template v-slot:toolbar>
      <!--begin::Page title-->
      <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
        <!--begin::Title-->
        <h1 class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Comissões</h1>
        <!--end::Title-->
        <!--end::Title-->
        <div class="mt-3" v-if="claims.length > 0">
          <p class="mb-0">
            <strong>Resultados:</strong> {{ claims.length }}/{{ total }}
          </p>
        </div>
      </div>
      <!--begin::Actions-->
      <div class="d-flex align-items-center gap-2 gap-lg-3 position-relative">
        <!--begin::Filter menu-->
        <div class="m-0">
          <!--begin::Menu toggle-->
          <a
            href="#"
            @click.prevent="isFiltersOpen = !isFiltersOpen"
            class="btn btn-sm btn-flex bg-body btn-color-gray-700 btn-active-color-primary fw-bold"
          >
          <!--begin::Svg Icon | path: icons/duotune/general/gen031.svg-->
          <span class="svg-icon svg-icon-6 svg-icon-muted me-1">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z" fill="currentColor" />
            </svg>
          </span>
          <!--end::Svg Icon-->Filtrar</a>
          <!--end::Menu toggle-->
          <!--begin::Menu 1-->
          <div
            class="menu menu-sub menu-sub-dropdown w-250px w-md-300px"
            :class="{
              'show': isFiltersOpen
            }"
            style="position: absolute; top: 40px; right: 0;"
          >
            <!--begin::Header-->
            <div class="px-7 py-5">
              <div class="fs-5 text-dark fw-bold">Opções de Filtro</div>
            </div>
            <!--end::Header-->
            <!--begin::Menu separator-->
            <div class="separator border-gray-200"></div>
            <!--end::Menu separator-->
            <!--begin::Form-->
            <div class="px-7 py-5">
              <!--begin::Input group-->
              <div class="mb-5">
                <!--begin::Label-->
                <label class="form-label fw-semibold">Busca:</label>
                <p style="font-size: 11px;">(Número interno, número da PI, número do PO ou nome do vendedor)</p>
                <!--end::Label-->
                <!--begin::Input-->
                <div>
                  <input type="text" class="form-control form-control-solid" placeholder="" v-model.trim="filters.search"/>
                </div>
                <!--end::Input-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="mb-5">
                <!--begin::Label-->
                <label class="form-label fw-semibold">Status da comissão:</label>
                <!--end::Label-->
                <!--begin::Input-->
                <div>
                  <select class="form-select" aria-label="Select example" v-model="filters.payment_status">
                    <option value="">Todos</option>
                    <option value="PENDING">Aguardando pagamento</option>
                    <option value="PAID">Pago</option>
                  </select>
                </div>
                <!--end::Input-->
              </div>
              <!--end::Input group-->
              <!--begin::Actions-->
              <div class="d-flex justify-content-end">
                <button type="reset" class="btn btn-sm btn-light btn-active-light-primary me-2" data-kt-menu-dismiss="true" @click="reset">Resetar</button>
                <button type="submit" class="btn btn-sm btn-primary" data-kt-menu-dismiss="true" @click="applyFilters">Aplicar</button>
              </div>
              <!--end::Actions-->
            </div>
            <!--end::Form-->
          </div>
          <!--end::Menu 1-->
        </div>
        <!--end::Filter menu-->
        <!--begin::Secondary button-->
        <!--end::Secondary button-->
      </div>
      <!--end::Actions-->
      <!--end::Page title-->
    </template>

    <!-- CONTENT -->
    <!--begin::Products-->
    <div class="card card-flush">
      <!--begin::Card body-->
      <div class="card-body pt-0">
        <template v-if="loader && claims.length === 0">
          <div class="py-5">
            <Loader size="big" />
          </div>
        </template>
        <template v-else-if="!loader && claims.length === 0">
          <p class="text-center pt-5">Nenhuma comissão encontrada</p>
        </template>
        <template v-else>
          <div class="table-responsive">
            <!--begin::Table-->
            <table class="table align-middle table-row-dashed fs-6 gy-5" id="kt_ecommerce_products_table">
              <!--begin::Table head-->
              <thead>
                <!--begin::Table row-->
                <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                  <th class="min-w-70px">Empresa</th>
                  <th class="min-w-70px">Anunciante</th>
                  <th class="min-w-70px">Número pagamento</th>
                  <th class="min-w-70px">Número PI</th>
                  <th class="min-w-70px">Prazo de pagamento</th>
                  <th class="min-w-70px">Status do pagamento</th>
                  <th class="min-w-70px">Vendedor</th>
                  <th class="min-w-70px">Valor comissão</th>
                  <th class="min-w-70px">Pagamento comissão</th>
                  <th class="min-w-150px">Ações</th>
                </tr>
                <!--end::Table row-->
              </thead>
              <!--end::Table head-->
              <!--begin::Table body-->
              <tbody class="fw-semibold text-gray-600">
                <!--begin::Table row-->
                <tr v-for="(claim, index) in claims" :key="index">
                  <td>
                    <span>
                      <router-link
                        :to="{ name: 'companies.show', params: { id: claim.order.company.id } }"
                      >
                        {{ claim.order.company.name }}
                      </router-link>
                    </span>
                  </td>
                  <td>
                    <span>
                      <router-link
                        :to="{ name: 'advertisers.show', params: { id: claim.order.advertiser.id } }"
                      >
                        {{ claim.order.advertiser.name }}
                      </router-link>
                    </span>
                  </td>
                  <td>
                    <span>
                      <a
                        href="javascript(0);"
                        @click.prevent="$bvModal.show(`order-detail-modal-${claim.order.id}`)"
                      >
                        {{ claim.order.identifier }}
                      </a>
                    </span>
                    <OrderDetailModal
                      :orderId="claim.order.id"
                    />
                  </td>
                  <td>
                    <span v-if="claim.order.insertion_order">
                      <router-link
                        :to="{ name: 'insertion.orders.show', params: { id: claim.order.insertion_order.id } }"
                        target="_blank"
                      >
                        {{ claim.order.insertion_order.identifier }}
                      </router-link>
                    </span>
                  </td>
                  <td>
                    <span>{{ claim.order.payment_expiration_date | formatDate('DD/MM/YYYY HH:mm:ss') }}</span>
                  </td>
                  <td>
                    <span>{{ claim.order.payment_status | formatPaymentStatus }}</span>
                  </td>
                  <td>
                    <span>{{ claim.claimed_user.name }}</span>
                  </td>
                  <td>
                    <span>{{ claim.commission_amount/100 | formatMoney }}</span>
                  </td>
                  <td>
                    <span>{{ claim.payment_status == 'PAID' ? 'Pago' : 'Pendente pagamento' }}</span>
                  </td>
                  <td>
                    <span v-if="claim.payment_status !== 'PAID'">
                      <Button
                        class="btn btn-sm btn-success ms-3"
                        v-b-modal="`mark-order-claim-paid-modal-${claim.id}`"
                        v-b-tooltip.hover title="Marcar como pago"
                      >
                        <i class="fa-solid fa-check p-0"></i>
                      </Button>
                      <MarkCommissionAsPaidModal
                        :order_claim="claim"
                        @paid="reset(false)"
                      />
                    </span>
                  </td>
                </tr>
                <!--end::Table row-->
              </tbody>
              <!--end::Table body-->
            </table>
          </div>
          <!--end::Table-->
          <div class="text-center py-5">
            <Button
              class="btn-primary"
              :loading="loader"
              @click="loadMore"
              v-if="isLoadMoreVisible"
            >
              Carregar mais
            </Button>
          </div>
        </template>
      </div>
      <!--end::Card body-->
    </div>
    <!--end::Products-->
    <!-- END CONTENT -->
  </MainLayout>
</template>

<script>

import OrderClaimsService from '@/modules/order-claims/services/order-claims-service'

import Button from '@/components/common/Button/Button'
import Loader from '@/components/common/Loader/Loader'
import MainLayout from '@/components/layouts/MainLayout'
import MarkCommissionAsPaidModal from '@/components/order-claims/MarkCommissionAsPaidModal.vue'
import OrderDetailModal from '@/components/orders/OrderDetailModal'

export default {
  components: {
    Button,
    Loader,
    MainLayout,
    MarkCommissionAsPaidModal,
    OrderDetailModal
  },
  metaInfo () {
    return {
      title: 'Comissões'
    }
  },
  data () {
    return {
      claims: [],
      filters: {
        search: '',
        payment_status: ''
      },
      isFiltersOpen: false,
      isLoadMoreVisible: false,
      loader: false,
      page: 1,
      total: 0
    }
  },
  created () {
    this.getOrderClaims()
  },
  mounted () {
  },
  computed: {
  },
  methods: {
    /**
     * Apply filters and search
     */
    applyFilters () {
      this.isFiltersOpen = false
      this.page = 1
      this.claims = []
      this.getOrderClaims()
    },
    /**
     * Get order claims
     */
    async getOrderClaims () {
      this.loader = true
      try {
        const claims = await OrderClaimsService.getOrderClaims({
          commission_payment_status: this.filters.payment_status,
          page: this.page,
          search: this.filters.search,
          status: 'APPROVED'
        })
        this.claims = [...this.claims, ...claims.data]
        this.isLoadMoreVisible = claims.meta.current_page !== claims.meta.last_page
        this.total = claims.meta.total
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    },
    /**
     * Load more
     */
    loadMore () {
      this.page++
      this.getOrders()
    },
    /**
     * Reset
     */
    reset (clearFilters = true) {
      if (clearFilters) {
        this.isFiltersOpen = false
        this.filters.search = ''
        this.filters.payment_status = ''
      }
      this.page = 1
      this.claims = []
      this.getOrderClaims()
    }
  }
}
</script>
